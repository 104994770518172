.form-custom-component {
    margin-top: -9px;
}

.style-success {
    color: #168821;
}

.style-success .mat-simple-snackbar-action {
    color: #168821;
}

.style-error {
    color: #E60000;
}

.style-error .mat-simple-snackbar-action {
    color: #E60000;
}

.style-warn {
    color: #FFCD07;
}

.style-warn .mat-simple-snackbar-action {
    color: #FFCD07;
}

.style-info {
    color: #155BCB;
}

.style-info .mat-simple-snackbar-action {
    color: #155BCB;
}

.semaphore-go {
    color: #A0C415;
}
.semaphore-warn {
    color: #F7B757;
}
.semaphore-stop {
    color: #E53919;
}

.bdrop {
    background-color: rgba(0, 0, 0, 0.32);
    backdrop-filter: blur(.1px);
}

.snack-bar-danger .mat-simple-snackbar-action {
    color: #E60000;
}

.mat-tab-header-pagination.mat-tab-header-pagination-after.mat-elevation-z4.mat-ripple {
    display: none;
}

.mat-tab-header-pagination.mat-tab-header-pagination-before.mat-elevation-z4.mat-ripple.mat-tab-header-pagination-disabled {
    display: none;
}

.info-attribute {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.hr-divider-top {
    margin-top: 0.25em;
}

.mat-menu-content:not(:empty) {
    padding: 0!important;
}

.mat-dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.form-item {
    flex: 1 0 0;
}

.icon-origem-estrangeiro {
    color: red;
}

.gov-br-text {
    color: #1351b4; 
    font-weight: bold;
}

@import '~bootstrap/dist/css/bootstrap.min.css';
