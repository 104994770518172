// Default Theme
@include angular-material-theme($theme);

// Dark Theme
.app-dark {
    @include angular-material-theme($dark-theme);
}

.color-primary,
.texto-color-primary {
    color: mat-color($primary);
}
